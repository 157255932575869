<template>
  <div class="basket-backGround">
    <!---------------------------EVENT MODAL-------------------------->
    <div class="modal" id="eventModal">
      <div class="modal-dialog">
        <div class="modal-content modalBasketDark">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Event Info</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <table class="table table-bordered" id="eventTableId">
              <tbody>
                <tr>
                  <th>Event name:</th>
                  <td>{{ this.clickedEvent.event_name }}</td>
                </tr>
                <tr>
                  <th>User:</th>
                  <td>
                    {{
                      this.eventUser.first_name + " " + this.eventUser.last_name
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pilots:</th>
                  <td
                    v-show="
                      this.clickedEventPilot !=
                      'DO NOT DELETE ME DO NOT DELETE ME'
                    "
                  >
                    {{ this.clickedEventPilot }}
                  </td>
                </tr>
                <tr>
                  <th>Drones:</th>
                  <td>{{ this.clickedEventDrone }}</td>
                </tr>
                <tr>
                  <th>Batteries:</th>
                  <td>{{ this.clickedEventBattery }}</td>
                </tr>
                <tr>
                  <th>Date Start:</th>
                  <td>
                    {{
                      new Date(this.clickedEvent.date_start).toLocaleString()
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Date End:</th>
                  <td>
                    {{ new Date(this.clickedEvent.date_end).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <th>Event description:</th>
                  <td>{{ this.clickedEvent.reason }}</td>
                </tr>

                <tr>
                  <th>Request Status:</th>
                  <td>{{ this.requestStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <!--  <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              id="eventModalEdit"
              @click="eventModalEdit()"
            >
              Edit
            </button> -->
            <button
              v-show="
                currentUser.role == 'admin' ||
                currentUser.role == 'semi-admin' ||
                currentUser.role == 'developer'
              "
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="eventModalDeleteBasket"
              @click="eventModalDelete(this.clickedEvent.event_id)"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="eventModalClose"
            >
              Close
            </button>
          </div>
          <br />
        </div>
      </div>
    </div>
    <button
      type="button"
      id="eventModalButton"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#eventModal"
    ></button>
    <!-- ----------------------USER EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="userEditModal"
      role="dialog"
      aria-labelledby="userEditModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editTitle">&nbsp; User edit:</h5>
            <h5 class="modal-title" id="userEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                Please fill the form if you want to edit the current User
                <br />
                <br />
                <label for="email">Email:&nbsp;</label>
                <input
                  v-model="editEmail"
                  type="text"
                  class="new-user-input2 form-control"
                  name="email"
                  id="emailInput"
                  disabled="disabled"
                  :placeholder="this.currentUser.email"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="firstName">First Name:&nbsp;</label>
                <input
                  v-model="editFirstName"
                  type="text"
                  name="firstName"
                  id="firstNameInput"
                  class="new-user-input2 form-control"
                  :placeholder="this.currentUser.first_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="lastName">Last Name:&nbsp;</label>
                <input
                  v-model="editLastName"
                  type="text"
                  name="lastName"
                  id="lastNameInput"
                  class="new-user-input2 form-control"
                  :placeholder="this.currentUser.last_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password"
                  >Password:&nbsp;
                  <a
                    href="#"
                    title="Password must contain: 
  -at least 8 characters 
  -at least 1 number
  -at least 1 lowercase character
  -at least 1 uppercase character"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-content="Popover text"
                    ><i class="fa-solid fa-circle-info"></i
                  ></a>
                </label>
                <input
                  v-model="editPassword"
                  type="password"
                  name="password"
                  id="passwordInput"
                  class="new-user-input2 form-control"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <button
                  @click="showPassword('password')"
                  class="eyeButtonBasket"
                  id="showPasswordButtonBasketPage"
                >
                  <i class="fa-solid fa-eye" id="pwEyeBasket"></i>
                  <i class="far fa-eye-slash" id="pwSlashedEyeBasket"></i>
                </button>
                <br />
                <br />
                <label for="password">Password again:&nbsp;</label>
                <input
                  v-model="editPasswordAgain"
                  type="password"
                  name="passwordAgain"
                  id="passwordAgainInput"
                  class="new-user-input2 form-control"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <button
                  @click="showPassword('passwordAgain')"
                  class="eyeButtonBasket"
                  id="showPasswordAgainButtonBasketPage"
                >
                  <i class="fa-solid fa-eye" id="pwAgainEyeBasket"></i>
                  <i class="far fa-eye-slash" id="pwAgainSlashedEyeBasket"></i>
                </button>
                <br />
                <br />

                <label for="phoneNum">Phone Number:&nbsp;</label>
                <input
                  v-model="editPhoneNum"
                  type="text"
                  name="phoneNum"
                  id="phoneNumInput3"
                  class="new-user-input2 form-control"
                  :placeholder="this.phoneNumPlaceholder"
                  @keydown="isInputEnter()"
                  maxlength="7"
                />
                <input
                  list="selected"
                  name="userSearch"
                  id="editPhoneNumSelectInput3"
                  class="new-user-input2 form-control"
                  autocomplete="off"
                  :placeholder="this.phoneselectedPlaceholder"
                  maxlength="2"
                  v-model="this.selectedNum"
                />

                <datalist id="selected">
                  <option>20</option>
                  <option>30</option>
                  <option>31</option>
                  <option>50</option>
                  <option>70</option>
                  <option>96</option>
                </datalist>
                <input
                  class="new-user-input2 form-control"
                  type="text"
                  id="plusPhoneBasket"
                  :value="this.plusPhone"
                  disabled
                />&nbsp;

                <br />
                <br />

                <label for="type">Organization:&nbsp;</label>&nbsp;

                <select
                  v-model="this.selectedOrganization"
                  class="techDropdown"
                  id="organizationInput1"
                >
                  <option hidden value="undefined">
                    {{ this.currentUser.organization }}
                  </option>
                  <option>DDC</option>
                  <option>JKK</option>
                  <option>AHJK</option>
                  <option>AK</option>
                  <option>EEKK</option>
                  <option>ESK</option>
                  <option>GIVK</option>
                  <option>KGK</option>
                  <option>MEK</option>
                  <option>MUK</option>
                  <option>Budapesti központ</option>
                  <option>ZalaZONE</option>
                  <option>None above</option>
                </select>
                <br v-show="this.selectedOrganization == 'None above'" />
                <br v-show="this.selectedOrganization == 'None above'" />
                <input
                  v-show="this.selectedOrganization == 'None above'"
                  v-model="editOrganization"
                  type="text"
                  name="organization"
                  id="organizationInput"
                  class="new-user-input2 form-control"
                  placeholder="Organization"
                  @keydown="isCreateEnter()"
                />
                <br />
                <br />
                <label for="role">Role:&nbsp;</label>
                <input
                  type="text"
                  class="new-user-input2 form-control"
                  name="role"
                  id="roleInput"
                  disabled="disabled"
                  :placeholder="this.currentUser.role"
                />
                <br />
                <br />
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    id="sureButtonUserEdit"
                    @click="userEdit(item, index)"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonUserEdit"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------USERS LIST MODAL-------------------------------- -->
    <!-- -------------------------------------USERS LIST MODAL-------------------------------- -->
    <div
      class="modal fade"
      id="usersModal"
      role="dialog"
      aria-labelledby="usersModal"
      aria-hidden="true"
    >
      <div class="large modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">&nbsp; Users:</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="allUsersList">
              <div class="container">
                <table
                  id="allUsersBasketTable"
                  class="allUsers table table-dark table-striped"
                >
                  <thead>
                    <tr>
                      <th>First Name:</th>
                      <th>Last Name:</th>
                      <th>Email:</th>
                      <th>Phone Number:</th>
                      <th>Organization:</th>
                      <th>Role:</th>
                      <th>Delete user:</th>
                      <th>Edit user:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.userArray"
                      :key="item.userId"
                    >
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].first_name }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].last_name }}
                      </td>

                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].email }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].phone_num }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].organization }}
                      </td>

                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="roleButton"
                        >
                          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
                          {{ this.userArray[index].role }}
                          <i class="fa-solid fa-caret-down" id="uIcon"></i>
                          &nbsp;
                        </button>
                        <div class="dropdown-menu calendarPageDropdownMenu">
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('developer', item)"
                            >Developer
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('admin', item)"
                            >Admin
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('semiAdmin', item)"
                            >Semi-Admin
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('user', item)"
                            >User
                          </a>
                        </div>
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn"
                          id="deleteUser"
                          @click="deleteUser(item, index)"
                        >
                          Delete User
                        </button>
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-secondary"
                          id="editUserButton"
                          data-bs-toggle="modal"
                          data-bs-target="#anotherUserEditModal"
                          @click="selectUser(item)"
                          v-if="this.userArray[index].role != 'admin'"
                        >
                          Edit User
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="cancelButtonCreateCalendarPage"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------ANOTHER USER EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="anotherUserEditModal"
      role="dialog"
      aria-labelledby="anotherUserEditModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content anotherModal">
          <div class="modal-header">
            <h5 id="editTitle">&nbsp; User edit:</h5>
            <h5 class="modal-title" id="userEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                <label for="email">Email:&nbsp;</label>
                <input
                  v-model="editEmail"
                  type="text"
                  class="new-user-input2 form-control"
                  name="email"
                  id="anotherUserEmailInputBasket"
                  disabled="disabled"
                  autocomplete="off"
                  :placeholder="this.selectedUser.email"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="firstName">First Name:&nbsp;</label>
                <input
                  v-model="editFirstName"
                  type="text"
                  name="firstName"
                  id="anotherUserFirstNameInputBasket"
                  class="new-user-input2 form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.first_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="lastName">Last Name:&nbsp;</label>
                <input
                  v-model="editLastName"
                  type="text"
                  name="lastName"
                  id="anotherUserLastNameInputBasket"
                  class="new-user-input2 form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.last_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password">Password:&nbsp;</label>
                <input
                  v-model="editPassword"
                  type="password"
                  name="password"
                  id="passwordInputAnotherBasket"
                  class="new-user-input2 form-control"
                  placeholder="Password"
                  autocomplete="off"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password">Password again:&nbsp;</label>
                <input
                  v-model="editPasswordAgain"
                  type="password"
                  name="passwordAgain"
                  id="passwordAgainInputAnotherBasket"
                  class="new-user-input2 form-control"
                  autocomplete="off"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <br />
                <br />

                <label for="phoneNum">Phone Number:&nbsp;</label>
                <input
                  v-model="editPhoneNum"
                  type="text"
                  name="phoneNum"
                  id="anotherUserphoneNumInput3"
                  class="new-user-input2 form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.phone_num"
                  @keydown="isInputEnter"
                  maxlength="8"
                />
                <select
                  v-model="this.selectedNum"
                  class="new-user-input2 form-control"
                  id="phoneTwoBasket"
                >
                  &nbsp;
                  <option hidden value="undefined">
                    {{ phoneselectedPlaceholder }}
                  </option>
                  <option>20</option>
                  <option>30</option>
                  <option>31</option>
                  <option>50</option>
                  <option>70</option>
                  <option>96</option>
                </select>
                <input
                  class="new-user-input2 form-control"
                  type="text"
                  id="plusPhoneAnotherBasket"
                  :value="this.plusPhone"
                  disabled
                />&nbsp;

                <br />
                <br />

                <label for="type">Organization:&nbsp;</label>&nbsp;

                <select
                  v-model="this.selectedOrganization"
                  class="techDropdown"
                >
                  <option hidden value="undefined">
                    {{ this.selectedUser.organization }}
                  </option>
                  <option>DDC</option>
                  <option>JKK</option>
                  <option>AHJK</option>
                  <option>AK</option>
                  <option>EEKK</option>
                  <option>ESK</option>
                  <option>GIVK</option>
                  <option>KGK</option>
                  <option>MEK</option>
                  <option>MUK</option>
                  <option>Budapesti központ</option>
                  <option>ZalaZONE</option>
                  <option>None above</option>
                </select>
                <br v-show="this.selectedOrganization == 'None above'" />
                <br v-show="this.selectedOrganization == 'None above'" />
                <input
                  v-show="this.selectedOrganization == 'None above'"
                  v-model="editOrganization"
                  type="text"
                  name="organization"
                  id="organizationInputAnother"
                  class="new-user-input2 form-control"
                  placeholder="Organization"
                  @keydown="isCreateEnter()"
                />
                <br />
                <br />
                <label for="role">Role:&nbsp;</label>
                <input
                  type="text"
                  class="new-user-input2 form-control"
                  name="role"
                  id="roleInputAnother"
                  disabled="disabled"
                  :placeholder="this.selectedUser.role"
                />
                <br />
                <br />
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    id="sureButtonUserEdit"
                    data-bs-dismiss="modal"
                    @click="anotherUserEdit(item, index)"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonUserEdit"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-secondary"
      id="home-button"
      @click="home"
    >
      <i class="fa-solid fa-home"></i>
    </button>
    <button
      data-toggle="tooltip"
      data-placement="right"
      title="List view"
      id="list-button"
      class="btn btn-secondary"
      type="button"
      @click="listPage()"
    >
      <i class="fa-solid fa-list"></i>
    </button>

    <button
      data-toggle="tooltip"
      data-placement="right"
      title="User edit"
      id="userEditButtonBasket"
      class="btn btn-secondary"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#userEditModal"
    >
      <i class="fas fa-cogs"></i>
    </button>
    <button
      data-toggle="tooltip"
      data-placement="right"
      title="Logout"
      id="logoutButton2Basket"
      class="btn btn-secondary"
      type="button"
      @click="logout()"
    >
      <i class="fas fa-sign-out-alt"></i>
    </button>
    <div
      v-show="
        currentUser.role == 'admin' ||
        currentUser.role == 'semiAdmin' ||
        currentUser.role == 'developer'
      "
    >
      <button
        data-toggle="tooltip"
        data-placement="right"
        title="Users"
        id="usersButtonBasket"
        class="btn btn-secondary"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#usersModal"
      >
        <i class="fa-solid fa-address-card"></i>
      </button>
    </div>

    <!-- ---------------------------------------EVENT CONTAINER------------------------------------ -->
    <div class="eventShow" v-show="this.eventShow">
      <div class="infoText">
        Please check the checklist before flight and after flight upload files
        on homepage (My Events table)
      </div>
      <div class="eventContainer container p-5 my-5">
        <div class="inputDivTitle">
          <div class="basketTitle">
            <i class="fa-solid fa-calendar"></i> Event
            <i class="fa-solid fa-calendar"></i>
          </div>
        </div>
        <div class="inputDiv-body">
          <form>
            <div class="leftEvent">
              <div class="eventNameRow">
                <label class="eventDivLabel" for="eventNameBasket"
                  >Event name:</label
                >
                <input
                  type="text"
                  name="eventNameBasket"
                  id="eventNameBasket"
                  class="event-input-basket form-control"
                  v-model="this.eventName"
                  required
                /><br />
              </div>

              <div class="reasonBoxDiv">
                <label class="eventDivLabel" for="reasonBox">Reasoning:</label>

                <textarea
                  type="text"
                  name="Reasonbox"
                  id="reasonBox"
                  class="event-input-basket form-control"
                  v-model="this.eventReason"
                  required
                  placeholder="Purpose of reservation, location (coordinates), other important information..."
                />
              </div>
              <br />

              <input
                class="form-check-input"
                type="checkbox"
                id="termsConditions"
                name="option1"
              />
              <label class="privacyCheck form-check-label"
                >You agree to the
                <button
                  type="button"
                  class="btn btn-primary"
                  id="termsButton"
                  data-bs-toggle="modal"
                  data-bs-target="#termsModal"
                >
                  Terms and Privacy Policy
                </button></label
              >
            </div>
            <br />

            <div class="rightEvent">
              <div class="startDatePickerRow">
                <label class="eventDivLabel" for="startDatePicker"
                  >Start Date:</label
                >
                <input
                  type="date"
                  name="startDatePicker"
                  id="startDatePicker"
                  class="event-input-basket form-control"
                  v-model="this.eventStart"
                  @change="selectDate()"
                  required
                /><br />
              </div>

              <div class="startTimePickerRow">
                <label class="eventDivLabel" for="startTimePicker"
                  >Start Time:</label
                >
                <input
                  type="time"
                  name="startTimePicker"
                  id="startTimePicker"
                  class="event-input-basket form-control"
                  v-model="this.eventStartTime"
                  @change="selectDate()"
                />
                Default value: 00:01<br />
              </div>
              <br />
              <div class="endDatePickerRow">
                <label class="eventDivLabel" for="endDatePicker"
                  >End Date:</label
                >
                <input
                  type="date"
                  name="endDatePicker"
                  id="endDatePicker"
                  class="event-input-basket form-control"
                  v-model="this.eventEnd"
                  @change="selectDate()"
                  required
                /><br />
              </div>

              <div class="endTimePickerRow">
                <label class="eventDivLabel" for="endTimePicker"
                  >End Time:</label
                >
                <input
                  type="time"
                  name="endTimePicker"
                  id="endTimePicker"
                  class="event-input-basket form-control"
                  v-model="this.eventEndTime"
                  @change="selectDate()"
                />
                Default value: 23:59<br />
              </div>
            </div>

            <button
              class="btn"
              type="button"
              id="eventShowButton1"
              @click="nextPage1()"
            >
              Next</button
            ><br />
          </form>
        </div>
      </div>
    </div>
    <!-- ---------------TERMS MODAL------------------ -->

    <!-- The Modal -->
    <div class="modal" id="termsModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content modalBasketDark">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Terms and Privacy Policy</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body" id="termsPolId">
            <h4>Magyar:</h4>
            A kölcsönvevő köteles az átvett eszközöket rendeltetésszerűen
            használni, állagát megóvni, használaton kívül az általában elvárható
            módon elzárva tartani. Kölcsönvevő az átvett eszközök esetleges
            eltűnése esetén az átvett eszközök teljes értékéig anyagi
            felelősséggel tartozik.
            <b class="termsPolIdRed"
              >Kölcsönvevő az eszközök átvételkori és átadáskori állapotát fotók
              feltöltésével hitelesíti, mely a foglalás véglegesítésének, és a
              kölcsön befejezésének feltétele.</b
            >
            Kölcsönvevő az átvett eszközökben a kölcsön időtartama alatt
            bekövetkezett mindennemű kárért teljes vagyoni és jogi felelősséggel
            tartozik, függetlenül, hogy a kár okozója ismert, vagy ismeretlen.
            Az átvett eszközökben keletkezett károkat kizárólag a tulajdonos
            hozzájárulásával, tulajdonos által megbízott javító végezheti.
            Kölcsönvevő köteles a kölcsönzési idő alatt bekövetkezett és okozott
            károkat kölcsönadó felé írásban, az eset részletes leírásával
            tudomására hozni. Az eszközök használata során harmadik személynek
            okozott kár esetén kölcsönvevő teljes jogi és anyagi felelősséggel
            tartozik.
            <br />
            <br />
            <h4>English:</h4>
            The borrower is obliged to dispose of the received assets in
            accordance with their intended purpose used, to preserve its
            texture, it is generally expected when not in use to keep closed.
            Borrower of the assets taken over in the event of its disappearance,
            up to the full value of the assets received is responsible.
            <b class="termsPolIdRed"
              >Borrower at the time of taking over the assets and verify its
              condition at the time of delivery by uploading photos, which is
              the reservation condition for its finalization and completion of
              the loan.</b
            >
            Borrower in the assets taken over occurred during the loan month
            bears full financial and legal responsibility for any kind of
            damage, regardless of whether the cause of the damage is known or
            unknown. It's taken over damage to equipment is the sole
            responsibility of the owner with the consent of a repairer
            commissioned by the owner. Borrower is obliged to pay for the first
            time that occurred during the loan period damages in writing to the
            lender, with a detailed description of the case to make aware. Use
            of tools for third parties in case of damage, the borrower bears
            full legal and financial responsibility belongs to.
          </div>

          <!-- Modal footer -->
          <div class="modal-footer modalFooterBasket">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--------------------- SUBMIT MODAL -------------------->
    <div class="modal" id="submitModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            ⚠️
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <h1 id="h1Style2">⚠️ Warning ⚠️</h1>
            <h1 id="h1Style2">
              Shipping the rented drones is the responsibility of the customer!
            </h1>
            <h1 id="h1Style2">
              Please check the checklist before flight and after flight upload
              files on homepage (My Events table)
            </h1>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              id="okButton"
              data-bs-dismiss="modal"
              @click="handleSubmit()"
            >
              I Understand
            </button>
          </div>
        </div>
      </div>
    </div>
    <!------------------------ Drone Info ------------------------------>
    <div class="droneShow" v-show="this.droneShow">
      <div class="infoText">
        Please check the checklist before flight and after flight upload files
        on homepage (My Events table)
      </div>
      <div class="droneContainer container p-5 my-5">
        <div class="inputDivTitle2">
          <div class="basketTitle">
            <i class="fa-solid fa-plane-up"></i> Choose drones
            <i class="fa-solid fa-plane-up"></i>
          </div>
        </div>

        <button
          class="btn btn-secondary"
          type="button"
          id="drone-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Choose a Drone <i class="fa-solid fa-caret-down"></i>
        </button>
        <div
          class="dropdown-menu drone-dropdown-menu"
          aria-labelledby="drone-dropdown"
        >
          <a
            v-for="(item, index) in this.avaliableDroneArray"
            :key="item.name"
            class="dropdown-item"
            href="#"
            @click="
              selectDrone(item);
              this.editIndex = index;
            "
            >{{ item.name }}</a
          >
        </div>
        <button
          id="addDroneButton"
          class="btn btn-secondary"
          type="button"
          @click="droneToBasket(this.selectedDrone)"
        >
          <i class="fa-solid fa-basket-shopping"></i>
        </button>
        <table class="table table-dark table-striped" id="spec-table">
          <tbody>
            <tr>
              <th><b>Drone Specification:</b></th>
              <td><b>Chosen drone:</b></td>
            </tr>
            <tr>
              <th>Drone name:</th>
              <td>{{ this.selectedDrone.name }}</td>
            </tr>
            <tr>
              <th>Drone type:</th>
              <td>{{ this.selectedDrone.type }}</td>
            </tr>
            <tr>
              <th>Manufacturer:</th>
              <td>{{ this.selectedDrone.manufacturer }}</td>
            </tr>
            <tr>
              <th>Registration number:</th>
              <td>{{ this.selectedDrone.registration_num }}</td>
            </tr>
            <tr>
              <th>Drone Battery Type:</th>
              <td>{{ this.parsedType }}</td>
            </tr>
            <tr>
              <th>Drone Battery Connector:</th>
              <td>{{ this.selectedDrone.connector }}</td>
            </tr>
            <tr>
              <th>Required Batteries:</th>
              <td>{{ this.selectedDrone.required_batteries }}</td>
            </tr>
            <tr>
              <th>Local:</th>
              <td>{{ this.selectedDrone.localization }}</td>
            </tr>
          </tbody>
        </table>

        <div class="basketBody">
          <div class="basketTitle">
            <i class="fa-solid fa-basket-shopping"></i> Basket
            <i class="fa-solid fa-basket-shopping"></i>
          </div>
          <button
            v-show="
              this.droneBasketArray.length > 0 ||
              this.batteryBasketArray.length > 0
            "
            type="button"
            id="allBasketClearButton"
            class="btn btn-danger"
            @click="clearAll()"
          >
            Clear all
          </button>

          <div class="basket-body">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="(item, index) in this.droneBasketArray"
                :key="item.serial_num"
                id="basketItems"
              >
                <table id="basketTable" class="table table-dark table-striped">
                  <tbody>
                    <tr>
                      <td>Name: {{ this.droneBasketArray[index].name }}</td>
                      <td>
                        Serial: {{ this.droneBasketArray[index].serial_num }}
                      </td>
                      <td>
                        <button
                          type="button"
                          id="basketClearButton"
                          class="btn btn-danger"
                          @click="clearDroneBasket()"
                        >
                          Clear
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li
                class="list-group-item"
                v-for="(item, index) in this.batteryBasketArray"
                :key="item.id"
              >
                ID:{{ this.batteryBasketArray[index].id }} Manufact.:{{
                  this.batteryBasketArray[index].manufacturer
                }}
                <button
                  type="button"
                  id="basketClearButton"
                  class="btn btn-danger"
                  @click="clearBatteryBasket()"
                >
                  Clear
                </button>
              </li>
            </ul>
          </div>
        </div>

        <button
          class="btn"
          type="button"
          id="eventShowButton2"
          @click="backPage1()"
        >
          Back
        </button>
        <button
          class="btn"
          type="button"
          id="eventShowButton2Next"
          @click="nextPage2()"
        >
          Next
        </button>
        <br />
      </div>
    </div>
    <!-- ---------------------------------BASKET---------------------------------- -->
    <div class="basketShow" v-show="this.basketShow">
      <div class="infoText">
        Please check the checklist before flight and after flight upload files
        on homepage (My Events table)
      </div>
      <div class="pilotContainer container p-5 my-5">
        <div class="inputDivTitle3">
          <i class="fa-solid fa-user-astronaut"></i> Pilots
          <i class="fa-solid fa-user-astronaut"></i>
        </div>

        <div class="pilotInner">
          <div
            class="pilotRow"
            v-for="(drone, index) in this.droneBasketArray"
            :key="drone.lab_id"
          >
            <label class="eventPilotNeed" for="pilotCheckbox"
              >Need pilot for {{ drone.name }}:</label
            >
            <input
              :id="'pilotCheckbox' + index"
              class="form-check-input pilotCheckbox"
              type="number"
              name="option1"
              value="0"
              min="0"
              max="10"
              checked
            />
          </div>
        </div>
        <button
          data-bs-toggle="modal"
          data-bs-target="#submitModal"
          type="button"
          class="btn btn-secondary"
          id="basketPageSubmitButton"
        >
          Submit
        </button>

        <button
          class="btn"
          type="button"
          id="eventShowButton3"
          @click="backPage2()"
        >
          Back
        </button>
      </div>
    </div>
    <div class="basket-calendar">
      <FullCalendar :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
//-----------------------------------------------------DATAPROVIDER IMPORT-----------------------
import dataProvider from "../utils/dataProvider.js";
const dp = dataProvider();
//---------------------------------------------------CALENDAR IMPORT----------------------------------------------
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { DateTime } from "luxon";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },

  methods: {
    async eventModalDelete(id) {
      if (
        confirm(
          `Are you sure you want to delete this event: ${this.clickedEvent.event_name}`
        )
      ) {
        for (
          let index = 0;
          index < this.calendarOptions.events.length;
          index++
        ) {
          const element = this.calendarOptions.events[index];
          if (element.id == id) {
            this.calendarOptions.events.splice(index, 1);
            index = this.calendarOptions.events.length;
          }
        }
        console.log("delete");

        await dp.softDeleteReservedDroneByEventId(id);
        await dp.softDeleteReservedDroneByEventId(id + 1);
        await dp.softDeleteReservedBatteriesByEventId(id);
        await dp.softDeleteReservedBatteriesByEventId(id + 1);
        await dp.softDeleteEventById(id);
        await dp.softDeleteEventById(id + 1);

        this.requests.splice(id, 1);
        this.calendarOptions.events = [];
        let events = await dp.getEventsNotDeleted();
        for (let element of events) {
          this.calendarOptions.events.push({
            title: element.event_name,
            start: element.date_start,
            end: element.date_end,
            backgroundColor: element.request_status,
            id: element.event_id,
          });
        }
      }
    },
    showPassword(key) {
      switch (key) {
        case "password":
          if (document.getElementById("passwordInput").type == "password") {
            document.getElementById("passwordInput").type = "text";
            document.getElementById("pwEyeBasket").style.display = "none";
            document.getElementById("pwSlashedEyeBasket").style.display =
              "block";
          } else if (document.getElementById("passwordInput").type == "text") {
            document.getElementById("passwordInput").type = "password";
            document.getElementById("pwEyeBasket").style.display = "block";
            document.getElementById("pwSlashedEyeBasket").style.display =
              "none";
          }
          break;
        case "passwordAgain":
          if (
            document.getElementById("passwordAgainInput").type == "password"
          ) {
            document.getElementById("passwordAgainInput").type = "text";
            document.getElementById("pwAgainEyeBasket").style.display = "none";
            document.getElementById("pwAgainSlashedEyeBasket").style.display =
              "block";
          } else if (
            document.getElementById("passwordAgainInput").type == "text"
          ) {
            document.getElementById("passwordAgainInput").type = "password";
            document.getElementById("pwAgainEyeBasket").style.display = "block";
            document.getElementById("pwAgainSlashedEyeBasket").style.display =
              "none";
          }
          break;
        default:
          break;
      }
    },

    nextPage1() {
      if (
        this.eventName &&
        this.eventStart &&
        this.eventEnd &&
        this.eventReason &&
        document.getElementById("termsConditions").checked
      ) {
        if (new Date(this.eventStart) <= new Date(this.eventEnd)) {
          this.eventShow = false;
          this.droneShow = true;
          this.basketShow = false;
        } else {
          alert("Selected start date is after end date.");
        }
      } else {
        alert("Please fill every data in the form!");
      }
    },
    nextPage2() {
      if (!this.droneBasketArray.length == 0) {
        this.eventShow = false;
        this.droneShow = false;
        this.basketShow = true;
      } else {
        alert("You didn't choosen any drone");
      }
    },
    backPage1() {
      this.eventShow = true;
      this.droneShow = false;
      this.basketShow = false;
    },
    backPage2() {
      this.eventShow = false;
      this.droneShow = true;
      this.basketShow = false;
    },

    ///--------------------------------------------EDIT USER----------------------------------------------
    async userEdit() {
      let editSelectPhoneNum = false;
      let editPhoneNum = false;
      let editPassword = false;
      let editPasswordAgain = false;

      let validatePassword = (password) => {
        return String(password).match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
      };
      let validatePhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{6,7})$/);
      };
      let validateSelectedPhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{2})$/);
      };

      if (
        validatePassword(this.editPassword) ||
        this.editPassword == undefined
      ) {
        document.getElementById("passwordInput").style.border =
          "medium solid #3f9140";
        editPassword = true;
      } else {
        document.getElementById("passwordInput").style.border =
          "medium solid #c9021d";
        alert(
          "Password must contain: \n -at least 8 characters \n -at least 1 number \n -at least 1 lowercase character\n -at least 1 uppercase character"
        );
      }

      if (this.editPassword == this.editPasswordAgain) {
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #3f9140";
        editPasswordAgain = true;
      } else {
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #c9021d";
        alert("Passwords doesn't match");
      }

      this.editEmail = document.getElementById("emailInput").placeholder;
      document.getElementById("emailInput").style.border =
        "medium solid #3f9140";

      if (
        validateSelectedPhoneNum(this.selectedNum) ||
        this.selectedNum == undefined
      ) {
        this.selectedNum = this.selectedNum || this.phoneselectedPlaceholder;
        document.getElementById("editPhoneNumSelectInput3").style.border =
          "medium solid #3f9140";
        editSelectPhoneNum = true;
      } else {
        document.getElementById("editPhoneNumSelectInput3").style.border =
          "medium solid #c9021d";
      }
      if (
        validatePhoneNum(this.editPhoneNum) ||
        this.editPhoneNum == undefined
      ) {
        this.editPhoneNum =
          this.editPhoneNum ||
          document.getElementById("phoneNumInput3").placeholder;
        document.getElementById("phoneNumInput3").style.border =
          "medium solid #3f9140";
        editPhoneNum = true;
      } else {
        document.getElementById("phoneNumInput3").style.border =
          "medium solid #c9021d";
      }

      this.editFirstName =
        this.editFirstName ||
        document.getElementById("firstNameInput").placeholder;

      document.getElementById("firstNameInput").style.border =
        "medium solid #3f9140";

      this.editLastName =
        this.editLastName ||
        document.getElementById("lastNameInput").placeholder;
      document.getElementById("lastNameInput").style.border =
        "medium solid #3f9140";

      this.editOrganization =
        this.editOrganization || this.selectedOrganization;
      document.getElementById("organizationInput1").style.border =
        "medium solid #3f9140";
      document.getElementById("organizationInput").style.border =
        "medium solid #3f9140";

      document.getElementById("plusPhoneBasket").style.border =
        "medium solid #3f9140";
      document.getElementById("roleInput").style.border =
        "medium solid #3f9140";

      if (
        editPassword &&
        editPasswordAgain &&
        editSelectPhoneNum &&
        editPhoneNum
      ) {
        await dp.editUserByEmail(
          this.editPassword,
          this.editEmail,
          this.plusPhone + this.selectedNum + this.editPhoneNum,
          this.editRole,
          this.editLastName,
          this.editFirstName,
          this.editOrganization
        );
        this.userArray = await dp.getUserList();
        this.currentUser = await dp.getUserByEmail(this.editEmail);
        this.phoneNumPlaceholder = await this.currentUser.phone_num.slice(
          5,
          12
        );
        this.phoneselectedPlaceholder = await this.currentUser.phone_num.slice(
          3,
          5
        );
        this.editUsername = undefined;
        this.editPassword = undefined;
        this.selectedNum = undefined;
        this.editPasswordAgain = undefined;
        this.editEmail = undefined;
        this.editPhoneNum = undefined;
        this.editFirstName = undefined;
        this.editLastName = undefined;
        this.editOrganization = undefined;
        document.getElementById("cancelButtonUserEdit").click();
        alert("Editing was successfull!");
      }
    },
    ///--------------------------------------------EDIT ANOTHER USER----------------------------------------------
    async anotherUserEdit() {
      this.editEmail = document.getElementById(
        "anotherUserEmailInputBasket"
      ).placeholder;

      this.selectedNum = this.selectedNum || this.phoneselectedPlaceholder;

      this.editPhoneNum =
        this.editPhoneNum ||
        document.getElementById("anotherUserphoneNumInput3").placeholder;

      this.editFirstName =
        this.editFirstName ||
        document.getElementById("anotherUserFirstNameInputBasket").placeholder;

      this.editLastName =
        this.editLastName ||
        document.getElementById("anotherUserLastNameInputBasket").placeholder;

      this.editOrganization =
        this.editOrganization || this.selectedOrganization;

      if (this.editPassword == this.editPasswordAgain) {
        await dp.editUserByEmail(
          this.editPassword,
          this.editEmail,
          this.plusPhone + this.selectedNum + this.editPhoneNum,
          this.editRole,
          this.editFirstName,
          this.editLastName,
          this.editOrganization
        );
        this.userArray = await dp.getUserList();
        this.phoneNumPlaceholder = await this.selectedUser.phone_num.slice(
          5,
          12
        );
        this.phoneselectedPlaceholder = await this.selectedUser.phone_num.slice(
          3,
          5
        );
        this.editUsername = undefined;
        this.editPassword = undefined;
        this.editPasswordAgain = undefined;
        this.editEmail = undefined;
        this.editPhoneNum = undefined;
        this.editFirstName = undefined;
        this.editLastName = undefined;
        this.editOrganization = undefined;

        alert("Editing was successfull!");
      } else alert("Passwords doesn't match!");
    },
    async changeRole(role, item) {
      await dp.editUserByEmail(
        undefined,
        item.email,
        item.phoneNum,
        role,
        item.lastName,
        item.firstName,
        item.organization
      );
      this.userArray = await dp.getUserList();
    },
    //-----------------------------------------------LOGOUT----------------------------------------------
    logout() {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("JWT");
      localStorage.removeItem("username");

      this.$router.push("/login");
    },
    //-------------------------------------------------------LIST PAGE-----------------------------------------------
    async listPage() {
      this.$router.push("/listPage");
    },
    //---------------------------------------------------------DATE SELECT-----------------------------------------------------
    async selectDate() { 
      if (this.eventStart && this.eventEnd) {


        
        let occupiedEvents = await dp.getEventByDate(
          (DateTime.fromISO(this.eventStart + "T" + this.eventStartTime + ":00")).toLocal().toUTC().toISO(),
          (DateTime.fromISO(this.eventEnd + "T" + this.eventEndTime + ":00")).toLocal().toUTC().plus({days: 1}).toISO()
        );
        let occupiedDrones = [];
        this.avaliableDroneArray = [...this.droneArray];
        for (let events of occupiedEvents) {
          let drones = await dp.getReservedDronesByEventId(events.event_id);
          for (let reservedDrones of drones) {
            occupiedDrones.push(reservedDrones);
          }
        }
        for (let index = 0; index < occupiedDrones.length; index++) {
          for (let i = 0; i < this.avaliableDroneArray.length; i++) {
            if (
              this.avaliableDroneArray[i].lab_id ==
                occupiedDrones[index].drone_lab_id &&
              occupiedDrones[index].drone_lab_id != undefined
            ) {
              this.avaliableDroneArray.splice(i, 1);
              i = this.avaliableDroneArray.length;
            }
          }
        }
      }
    },

    /*--------------------BATTERY TO BASKET----------*/
    batteryToBasket(item) {
      if (item.lab_id) {
        this.batteryBasketArray.push(item);
      }
    },
    /* ------------DRONE SELECT------------------------------ */
    async selectDrone(item) {
      let droneBattery = await dp.getDroneBatteryById(item.lab_id);
      let droneInsurance = await dp.getDroneInsuranceById(item.lab_id);
      this.selectedDrone = {
        name: item.name,
        type: item.type,
        serial_num: item.serial_num,
        manufacturer: item.manufacturer,
        registration_num: item.registration_num,
        start: droneInsurance.start,
        end: droneInsurance.end,
        number: droneInsurance.number,
        lab_id: item.lab_id,
        batteryType: droneBattery.type,
        connector: droneBattery.connector,
        required_batteries: droneBattery.required_batteries,
        localization: item.localization,
        comment: item.comment,
        bought: item.bought,
      };

      this.batteryTypeLength = JSON.stringify(
        this.selectedDrone.batteryType
      ).length;

      this.parsedType = JSON.stringify(this.selectedDrone.batteryType)
        .slice(2, this.batteryTypeLength - 2)
        .replace(/["]/g, " ");
    },
    /* ------------BATTERY SELECT------------------------------ */
    selectBattery(item) {
      this.selectedBattery = item;
    },
    /*--------------------DRONE TO BASKET----------*/
    droneToBasket(item) {
      if (item.lab_id) {
        if (this.droneBasketArray.some((e) => e.name === item.name)) {
          alert("Drone is already in the basket");
        } else {
          this.droneBasketArray.push(item);
        }
      } else {
        alert("Select a drone!");
      }
    },
    clearBatteryBasket(item, index) {
      this.batteryBasketArray.splice(index, 1);
    },

    clearDroneBasket(item, index) {
      this.droneBasketArray.splice(index, 1);
    },

    async home() {
      this.$router.push("/");
    },
    selectUser(item) {
      this.selectedUser = item;
    },
    ////-----------------------------------CLEAR BASKET-----------------------------
    clearAll() {
      this.batteryBasketArray = [];
      this.droneBasketArray = [];
      this.selectedDrone = [];
      this.parsedType = undefined;
    },
    /* ----------------------SEND MAIL----------------------- */
    async sendMail(
      startDate,
      startDateTime,
      endDate,
      endDateTime,
      user,
      droneBasketArray,
      eventReason
    ) {
      let dronesStr = "";
      for (let drones of droneBasketArray) {
        dronesStr += drones.name + ", ";
      }
      let from = "dron@tilb.sze.hu";
      let toArray = [];
      this.userArray.forEach((element) => {
        if (element.role == "admin") {
          toArray.push(element.email);
        }
      });
      let to = toArray;
      let subject =
        "Drone request from " + user.last_name + " " + user.first_name;

      let text =
        "Tisztelt Admin felhasználó \nÉn " +
        user.last_name +
        " " +
        user.first_name +
        " szeretném lefoglalni a(z) " +
        dronesStr +
        " nevű drónt/drónokat." +
        "\nFoglaló e-mail címe: " +
        user.email +
        "\nFoglalás kezdete: " +
        startDate +
        " " +
        startDateTime +
        "\nFoglalás vége: " +
        endDate +
        " " +
        endDateTime +
        "\nReason: " +
        eventReason +
        "\nKérlek engedélyezd a foglalást a : " +
        "https://dronefoglalo.ddc.sze.hu" +
        " címen. Előre is köszönöm." +
        "\nÜdvözlettel:\n" +
        user.last_name +
        " " +
        user.first_name;

      await dp.sendMail(from, to, subject, text);
    },
    /* ----------------------------DELETE USER--------------------------------- */
    async deleteUser(item, index) {
      if (confirm("Are you sure you want to DELETE this user?")) {
        this.pilotUser = await dp.getPilotByUserUuid(item.uuid);
        this.pilotDelete = [];
        this.userDelete = [];
        if (this.pilotUser) {
          this.pilotDelete = await dp.getReservedDronesByPilotId(
            this.pilotUser.licence_id
          );
          this.userDelete = await dp.getReservedDronesByUserId(
            this.pilotUser.user_uuid
          );
        } else {
          this.userDelete = await dp.getReservedDronesByUserId(item.uuid);
          this.pilotDelete = [];
        }

        console.log(
          this.pilotUser,
          this.userDelete.length,
          this.pilotDelete.length,
          index
        );
        if (this.pilotUser) {
          //User has pilot added to it
          if (
            (this.userDelete.length >= 1 && this.pilotDelete.length >= 1) ||
            (this.userDelete.length >= 1 && this.pilotDelete.length == 0) ||
            (this.userDelete.length == 0 && this.pilotDelete.length >= 1)
          ) {
            // 1 1
            if (confirm("This user and pilot is connected to an event!")) {
              await dp.softDeleteUserByEmail(item);
              await dp.softDeletePilotImagesByLicenceId(this.pilotUser);
              await dp.softDeletePilotById(this.pilotUser);
              this.userArray.splice(index, 1);
            }
          } else {
            // 0 0
            await dp.deletePilotImagesByLicenceId(this.pilotUser);
            await dp.deletePilotById(this.pilotUser);
            await dp.deleteUserByEmail(item);
            this.userArray.splice(index, 1);
          }
        } else {
          //User dont have pilot added to it
          if (this.userDelete.length >= 1) {
            // 1 1
            if (confirm("This user and pilot is connected to an event!")) {
              await dp.softDeleteUserByEmail(item);
              this.userArray.splice(index, 1);
            }
          } else {
            // 0 0
            await dp.deleteUserByEmail(item);
            this.userArray.splice(index, 1);
          }
        }
      }
      // this.userArray = await dp.getUserListNotDeleted();
    },

    //------------------------------------------------SUBMIT HANDLE---------------------------------------------------------
    async handleSubmit() {
      if (
        (this.eventName,
        this.eventStartTime,
        this.eventEnd,
        this.eventEndTime,
        this.eventReason)
      ) {
        let user = await dp.getUserByEmail(localStorage.getItem("userEmail"));
        await dp.addEvent(
          this.eventName,
          (DateTime.fromISO(this.eventStart + "T" + this.eventStartTime + ":00")).toLocal().toUTC().toISO(),
          (DateTime.fromISO(this.eventEnd + "T" + this.eventEndTime + ":00")).toLocal().toUTC().toISO(),
          "#6e282f",
          this.eventReason,
        );
        let afterEndDate = (DateTime.fromISO(this.eventStart + "T" +  "00:00:00")).toLocal().toUTC().plus({days: 1}).toISO()
        
        let afterEndDateEnd = (DateTime.fromISO(this.eventEnd + "T" + "23:59:00")).toLocal().toUTC().plus({days: 1}).toISO();
        
        await dp.addEvent(
          "Drone check after event: " + this.eventName,
          afterEndDate,
          afterEndDateEnd,
          "#808080"
        );
        let events = await dp.getEventsNotDeleted();
        this.calendarOptions.events = [];
        for await (let element of events) {

          this.calendarOptions.events.push({
            title: element.event_name,
            start: element.date_start,
            end: element.date_end,
            backgroundColor: element.request_status,
            id: element.event_id,
          });
        }
        for (let index = 0; index < this.droneBasketArray.length; index++) {
          if (document.getElementById("pilotCheckbox" + index).value) {
            //add drone with NEEDED Dummy Pilot cuz will be needed later
            let neededPilots = document.getElementById(
              "pilotCheckbox" + index
            ).value;
            console.log(neededPilots);
            let pilotarray = [];
            if (document.getElementById("pilotCheckbox" + index).value == 0) {
              pilotarray.push("neededNo");
            } else {
              for (let index = 0; index < neededPilots; index++) {
                pilotarray.push("neededYes");
              }
            }

            await dp.addDroneToEvent(
              events[events.length - 2].event_id,
              user.uuid,
              pilotarray,
              this.droneBasketArray[index].lab_id
            );
            //Add drone to check with NOT NEEDED dummy pilot
            await dp.addDroneToEvent(
              events[events.length - 1].event_id,
              user.uuid,
              ["neededNo"],
              this.droneBasketArray[index].lab_id
            );
          } else {
            ///add drone with NOT NEEDED dummy pilot
            await dp.addDroneToEvent(
              events[events.length - 2].event_id,
              user.uuid,
              ["neededNo"],
              this.droneBasketArray[index].lab_id
            );
            //Add drone to check with NOT NEEDED dummy pilot
            await dp.addDroneToEvent(
              events[events.length - 1].event_id,
              user.uuid,
              ["neededNo"],
              this.droneBasketArray[index].lab_id
            );
          }
        }

        this.requests = await dp.getRequestList();
        this.sendMail(
          this.eventStart,
          this.eventStartTime,
          this.eventEnd,
          this.eventEndTime,
          user,
          this.droneBasketArray,
          this.eventReason
        );
        this.clearAll();
        this.backPage1();
        this.eventReason = undefined;
        this.eventName = undefined;
        this.eventStart = undefined;
        this.eventStartTime = "00:01";
        this.eventEnd = undefined;
        this.eventEndTime = "23:59";
      } else alert("Please fill in the title for the event.");
    },
    /* ---------------------CALENDAR------------------------------ */
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    async handleEventClick(clickInfo) {
      this.clickedEvent = await dp.getEventById(clickInfo.event.id);

      let reserved_drones = await dp.getReservedDronesByEventId(
        clickInfo.event.id
      );
      this.eventUser = await dp.getUserByUuid(reserved_drones[0].user_uuid);

      let reservedBatteries = await dp.getReservedBatteriesByEventId(
        clickInfo.event.id
      );

      let drones = [];
      let pilots = [];
      for (let element of reserved_drones) {
        drones.push(element.drone_lab_id);
        pilots.push(element.pilot_id);
      }
      let pilotIndex = 0;
      for (const pilot of pilots) {
        pilots[pilotIndex] = await dp.getPilotById(pilot);
        pilots[pilotIndex] = await dp.getUserByUuid(
          pilots[pilotIndex].user_uuid
        );
        pilots[pilotIndex] =
          pilots[pilotIndex].first_name + " " + pilots[pilotIndex].last_name;
        pilotIndex++;
      }
      let batteries = [];
      for (let element of reservedBatteries) {
        batteries.push(element.battery_lab_id);
      }
      if (this.clickedEvent.request_status == "#6e282f") {
        this.requestStatus = "Not accepted yet";
      } else {
        this.requestStatus = "Accepted";
      }
      this.clickedEventDrone = [...drones].join();
      this.clickedEventPilot = [...pilots].join();
      this.clickedEventBattery = [...batteries].join();

      document.getElementById("eventModalButton").click();
    },
    handleEvents(/* events */) {},
    getEvents() {
      console.log("getEvents fired");
    },

    /* ---------------------CALENDAR END------------------------------ */
  },

  async mounted() {
    console.log("mounted");
    this.droneArray = await dp.getDroneNotDeleted();
    this.batteryArray = await dp.getBatteryNotDeleted();

    this.currentUser = await dp.getUserByEmail(
      localStorage.getItem("userEmail")
    );
    this.phoneNumPlaceholder = await this.currentUser.phone_num.slice(5, 12);
    this.phoneselectedPlaceholder = await this.currentUser.phone_num.slice(
      3,
      5
    );
    this.pilotArray = await dp.getPilotList();
    this.droneBasketArray = JSON.parse(localStorage.getItem("droneArray"));
    this.batteryBasketArray = JSON.parse(localStorage.getItem("batteryArray"));
    this.userArray = await dp.getUserList();

    //--------------------------------ADD EVENTS TO CALENDAR--------------------------------------------
    let events = await dp.getEventsNotDeleted();

    for await (let element of events) {
      this.calendarOptions.events.push({
        title: element.event_name,
        start: element.date_start,
        end: element.date_end,
        backgroundColor: element.request_status,
        id: element.event_id,
      });
    }
  },
  data() {
    return {
      clickedEvent: {
        event_name: undefined,
        date_start: undefined,
        date_end: undefined,
        reason: undefined,
      },

      eventUser: { first_name: undefined, last_name: undefined },
      clickedEventDrone: [],
      clickedEventPilot: [],
      clickedEventBattery: [],
      requestStatus: undefined,

      selectedOrganization: undefined,
      plusPhone: "+36",
      selectedNum: undefined,
      selectedLevel: "Licence level",
      selectedOrg: "Organisation",
      selectedBought: "Bought",
      eventShow: true,
      droneShow: false,
      basketShow: false,
      /* -----------------------  EDIT USER -------------------------------*/
      editUsername: undefined,
      editPassword: undefined,
      editPasswordAgain: undefined,
      editEmail: undefined,
      editPhoneNum: undefined,
      editFirstName: undefined,
      editLastName: undefined,
      editOrganization: undefined,

      //---------------------------EVENT--------------------------------
      needPilot: false,

      /* -----------------------  SELECTED DRONE -------------------------------*/
      selectedDrone: {
        name: undefined,
        type: undefined,
        serial_num: undefined,
        manufacturer: undefined,
        registration_num: undefined,
        start: undefined,
        end: undefined,
        number: undefined,
        lab_id: undefined,
        batteryType: undefined,
        connector: undefined,
        required_batteries: undefined,
        localization: undefined,
        comment: undefined,
        bought: undefined,
      },
      //-------------PARSE---------------
      batteryTypeLength: undefined,
      parsedType: undefined,

      selectedBattery: {
        id: undefined,
        cell_num: undefined,
        capacity: undefined,
        manufacturer: undefined,
        name: undefined,
        connector: undefined,
        technology: undefined,
        c_value: undefined,
        type: undefined,
        localization: undefined,
        lab_id: undefined,
      },

      eventReason: undefined,
      eventName: undefined,
      eventStart: undefined,
      eventStartTime: "00:01",
      eventEnd: undefined,
      eventEndTime: "23:59",
      rider: undefined,
      ///---------------------DRONE ARRAYS-------------------------------
      droneArray: [],
      avaliableDroneArray: [],

      ///---------------------BATTERY ARRAYS-------------------------------

      batteryArray: [],
      //--------------------PILOT DATA-------------------
      pilotArray: [],
      //-------------------BASKET------------------

      droneBasketArray: [],
      batteryBasketArray: [],
      //----------------------------EDIT ANOTHER USER--------------

      selectedUser: {
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },
      /* -----------------------USER DATA -------------------------------*/
      phoneNumPlaceholder: undefined,
      phoneselectedPlaceholder: undefined,
      user: localStorage.getItem("username"),
      currentUser: {
        firstName: undefined,
        lastName: undefined,
        userName: undefined,
        email: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },
      userArray: [],
      /* -----CALENDAR------ */
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        initialEvents: "events",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        events: [],
      },

      id: 0,
      /* ---------CALENDAR END----- */
    };
  },
};
</script>

<style>
.modalBasketDark {
  background-color: #212529;
}
#eventTableId {
  color: #fff;
}
#h1Style1 {
  color: #e62e2e;
  background-color: #ffe100;
}

#h1Style2 {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.modalFooterBasket {
  height: 70px;
}
.termsPolIdRed {
  color: rgb(192, 53, 67);
}
#termsPolId {
  color: white;
  text-align: justify;
}
#anotherUserFirstNameInputBasket {
  width: 54%;
}
#phoneTwoBasket {
  width: 45px;
}
#eventNameBasket {
  width: 200px;
}
#passwordInputAnotherBasket {
  width: 54%;
}
#anotherUserphoneNumInput3 {
  width: 85px;
}
#passwordAgainInputAnotherBasket {
  width: 54%;
}
#anotherUserLastNameInputBasket {
  width: 54%;
}
#anotherUserEmailInputBasket {
  width: 54%;
}
#startDatePicker {
  width: 100%;
}
#startTimePicker {
  width: 100%;
}
#endDatePicker {
  width: 100%;
}
#endTimePicker {
  width: 100%;
}
#eventShowButton1 {
  float: right;
}
#plusPhoneBasket {
  width: 55px;
}
#allUsersBasketTable {
  font-size: 14px;
}
#plusPhoneAnotherBasket {
  width: 55px;
}
#editPhoneNumSelectInput3 {
  max-width: 55px;
}
#eventModalButton {
  display: none;
}
#editUserButton {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  font-size: 14px;
  border-radius: 12px;
  height: 40px;
}
#editUserButton:hover {
  background-color: rgb(99, 116, 69);
}
.dropdown-item {
  cursor: pointer;
}
.techDropdown {
  font-size: 16px;
  height: 40px;
  background-color: #fff;
  color: #4a4a4a;
  width: 54%;
  float: right;
  border-radius: 5px;
}
.fc .fc-daygrid-body {
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #59555e;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  color: #fff;
}
.fc-daygrid-dot-event .fc-event-title {
  color: #fff;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#usersButtonBasket {
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: -27px;
  left: 200px;
  width: 50px;
}
#usersButtonBasket:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#logoutButton2Basket {
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  right: 5px;
  float: right;
  width: 50px;
}
#logoutButton2Basket:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#userEditButtonBasket {
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  left: 40px;
  width: 50px;
}
#userEditButtonBasket:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
.fa-circle-info {
  color: #6d6875;
}
.large {
  max-width: 100vw;
  width: 90vw;
}
.allUsersList {
  max-height: 500px;
  overflow: auto;
  color: #fff;
  width: 100%;
  margin: auto;
}
#allUsersList td {
  font-weight: normal;
  max-height: 5px;
  padding: 5px;
  margin: auto;
  font-size: 14px;
  color: #fff;
}
#allUsersList th {
  padding: 5px;
  font-size: 14px;
  color: #fff;
}

#roleButton {
  font-size: 14px;
  color: #fff;
}
#deleteUser {
  font-size: 14px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-radius: 12px;
  height: 40px;
}
#deleteUser:hover {
  background-color: rgb(144, 55, 64);
  color: #fff;
  border-color: rgb(144, 55, 64);
}
.infoText {
  font-size: 20px;
  margin: -30px;
  margin-left: 25%;
  color: #fff;
  width: 900px;
  background-color: rgba(33, 37, 41, 0.7);
}
#pwEyeBasket {
  bottom: 0px;
}
#pwSlashedEyeBasket {
  display: none;
  bottom: 0px;
}
#pwAgainEyeBasket {
  bottom: 0px;

  position: relative;
}
#pwAgainSlashedEyeBasket {
  display: none;
  bottom: 0px;

  position: relative;
}

#eyeIcon {
  bottom: 0px;
  position: relative;
}

#slashedEye {
  display: none;
  bottom: 0px;
  position: relative;
}
#showPasswordButtonBasketPage {
  position: absolute;
  left: 355px;
  bottom: 245px;
}
#showPasswordAgainButtonBasketPage {
  position: absolute;
  left: 355px;
  bottom: 190px;
}
.eyeButtonBasket {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: black;
  position: relative;
  bottom: 28px;
  float: right;
  z-index: 2;
  margin: 2px;
  min-height: 24px;
  min-width: 20px;
}
.new-user-input2 {
  float: right;
  width: 54%;
}

#phoneNumInput3 {
  max-width: 90px;
}
.btn-close-white {
  color: #fff;
  opacity: 1;
}
.calendarPageDropdownMenu {
  max-height: 150px;
  overflow-y: auto;
  width: 10%;
}
.modal-footer {
  height: 60px;
}
#termsButton {
  background-color: transparent;
  border-color: transparent;
  color: #75ceff;
  text-decoration: underline;
  padding: 0px;
  font-size: 14px;
}
.pilotCheckbox {
  margin-left: 10px;
  width: 50px;
}

.pilotTitle {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.pilotInner {
  padding: 5px;
  margin-top: 10px;
  width: 100%;
  max-height: 380px;
  overflow: auto;
}
.pilotRow {
  color: #000;
  padding: 5px;
}
.pilotCheckbox {
  position: relative;
}
.privacyCheck {
  font-size: 14px;
}
.rightEvent {
  margin-top: -50px;
  float: right;
  margin-right: 50px;
}
.leftEvent {
  margin-top: 0px;

  float: left;
}
.checkEvent {
  position: relative;
  top: 20px;
  left: 0px;
  height: 50px;
}
#eventShowButton1 {
  width: 80px;
  border-radius: 50px;
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
  position: absolute;
  top: 470px;
  right: 10px;
}
#eventShowButton1:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#eventShowButton2Next {
  width: 80px;
  border-radius: 50px;
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
  float: right;
  position: absolute;
  top: 740px;
  right: 10px;
}
#eventShowButton2Next:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#eventShowButton2 {
  position: absolute;
  top: 740px;
  width: 80px;
  float: left;
  border-radius: 50px;
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
  left: 10px;
}
#eventShowButton2:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#eventShowButton3 {
  position: absolute;
  float: left;
  bottom: 10px;
  left: 10px;
  width: 80px;
  border-radius: 50px;
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}
#eventShowButton3:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}

.event-input-basket {
  width: 200px;
}
#basketPageProfile {
  margin-top: 0px;
  float: right;
}
#listButton {
  border-radius: 50px;
  background-color: rgba(156, 158, 161, 0.7);
  color: white;
  border-color: #4a4a4a;
  position: absolute;
  top: 10px;
  left: 80px;
  width: 50px;
  z-index: 1;
}

#drone-dropdown {
  background-color: #4f5c72;
  color: #fff;
  top: -30px;
  border-radius: 12px;
  border-color: #4f5c72;
  left: 250px;
  position: relative;
}
#drone-dropdown:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
.drone-dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}
.dropdown-item:hover {
  background-color: #cdc5da;
}
.eventPilotNeed {
  color: #fff;
}
.pilotCheckbox {
  height: 25px;
  top: -5px;
  border-radius: 10px;
}
#addDroneButton {
  background-color: #4f5c72;
  color: #fff;
  border-radius: 12px;
  top: -30px;
  position: relative;
  z-index: 2;
  left: 260px;
  border-color: #4f5c72;
}
#addDroneButton:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#basketTable td {
  vertical-align: middle;
}
#basketItems {
  background-color: #212529;
}

#spec-table {
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  padding: 5px;
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  margin: auto;
  width: 100%;
  bottom: 20px;
}
#spec-table td {
  padding: 5px;
  font-weight: normal;
  color: #fff;
  min-width: 70px;
}
#spec-table th {
  padding: 5px;
  color: #fff;
  font-weight: normal;
}
.table-wrapper-scroll-y {
  display: block;
}
.table-wrapper-scroll-x {
  display: block;
}
.eventDivLabel {
  width: 100px;
}
#logoutButtonBasketPage {
  background-color: #ff0084;
  border-color: #212529;
  color: #fff;
  height: 40px;
}
#basketPageSubmitButton {
  position: absolute;
  border-radius: 50px;
  bottom: 10px;
  right: 10px;

  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#basketPageSubmitButton:hover {
  background-color: rgb(99, 116, 69);
}
#okButton {
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#okButton:hover {
  background-color: rgb(99, 116, 69);
}
#sureButtonUserEdit {
  color: #fff;
  background-color: rgb(99, 116, 69);
  border-color: rgb(99, 116, 69);
}

#sureButtonUserEdit:hover {
  background-color: rgb(64, 76, 43);
}
#cancelButtonUserEdit {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#cancelButtonUserEdit:hover {
  background-color: rgb(144, 55, 64);
}
.eventContainer {
  background-color: #212529;
  border: 2px solid #212529;
  width: 55vw;
  top: 80px;
  height: 75vh;
  max-height: 570px;
  float: left;
  left: 100px;
  color: #fff;
  border-radius: 12px;
  position: absolute;
  overflow: auto;
}
.droneContainer {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  width: 55vw;
  top: 80px;
  height: 75vh;
  max-height: 570px;
  float: left;
  left: 100px;
  border-radius: 12px;
  position: absolute;
  overflow: auto;
}
.pilotContainer {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  width: 55vw;
  top: 80px;
  height: 75vh;
  max-height: 570px;
  float: left;
  left: 100px;
  border-radius: 12px;
  position: absolute;
}
.basketBody {
  border: 2px solid #fff;
  border-radius: 12px;
  height: 300px;
  width: 100%;
  position: relative;
}
.basketTitle {
  font-size: 20px;
  margin: auto;
  position: relative;
  width: 100%;
  text-align: center;
  color: #fff;
}
.inputDivTitle {
  margin: auto;
  position: relative;
  top: -40px;
  font-size: 28px;
}
.inputDivTitle2 {
  margin: auto;
  position: relative;
  top: -40px;
  font-size: 28px;
}
.inputDivTitle3 {
  margin: auto;
  position: relative;
  top: -40px;
  font-size: 28px;
  text-align: center;
}
.eventNameRow {
  margin-top: -20px;
}

.reasonBoxDiv {
  float: left;
  margin-top: 0px;
  z-index: 5;
  margin-bottom: 10px;
}
#reasonBox {
  height: 100px;
  width: 200px;
  font-size: 14px;
}

.inputDiv-body {
  color: #fff;
  font-size: 16px;
  margin: 10px;
  max-height: 300px;
}
.basket-backGround {
  background-color: #212529;
  background-image: url("../assets/kep2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  color: #fff;
}

#home-button {
  position: relative;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  border-radius: 50px;
  top: 10px;
  left: 20px;
  width: 50px;
}
#home-button:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}
#list-button {
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  left: 30px;
  width: 50px;
}
#list-button:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}
li.list-group-item {
  background-color: rgba(156, 158, 161, 0.7);
  color: #000;
  padding: 5px;
  margin-bottom: 5px;
}
/* ----------SCROLLBAR-----------*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6d6875;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f5c72;
}

.my-custom-scrollbar {
  position: relative;
  overflow: auto;
}

/* -----------------------CALENDAR-------------------------------------- */
.basket-calendar {
  top: 130px;
  position: absolute;
  right: 100px;
  background-color: #212529;
  border: 5px solid #212529;
  border-radius: 12px;
  max-width: 800px;
  float: right;
  height: 370px;
  width: 470px;
  font-size: 12px;
  color: #fff;
}
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 360px;
}
.fc .fc-button-primary {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}

.fc .fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*---------------------------Előre nyíl naptárban rákattnál---------------------*/
button.fc-next-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*---------------------------Hátra nyíl naptárban rákattnál---------------------*/
button.fc-prev-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*---------------------------Today alapszín ha aktív, azaz todayre mentél---------------------*/
.fc .fc-button-primary:disabled {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}
/*---------------------------Today rákattnál, ráviszed az egeret---------------------------*/
button.fc-today-button.fc-button.fc-button-primary:hover {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

/*--------------------------- Month week day-nél az aktív színe---------------------*/
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}
/*--------------------------- Week-nél a rákattintás színe---------------------*/
button.fc-timeGridWeek-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*--------------------------- Month nál a rákattintás színe---------------------*/
button.fc-dayGridMonth-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*--------------------------- Day-nél a rákattintás színe---------------------*/
button.fc-timeGridDay-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
.fc-daygrid-day-bg:hover {
  color: #4a4a4a;
  background-color: #4a4a4a;
}

/* ------------------------BASKET-------------------------------- */
.basket {
  margin-top: -50px;
  overflow-y: visible;
}

.basket-body {
  color: #fff;
  font-size: 14px;
  margin: 50px;
  max-height: 210px;

  overflow: auto;
}
#basketClearButton {
  float: right;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  height: 30px;
  font-size: 12px;
}
#allBasketClearButton {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  right: 30px;
  position: relative;
  line-height: 25px;
  font-size: 12px;
  float: right;
}

#basketClearButton:hover {
  background-color: rgb(144, 55, 64);
}
#allBasketClearButton:hover {
  background-color: rgb(144, 55, 64);
}

@media (max-width: 1490px) and (min-width: 1200px) {
  .basket-calendar {
    top: 0px;
    float: none;
    margin: auto;
    position: relative;
    right: 0px;
    width: 370px;
    font-size: 9px;
  }
  .eventContainer {
    width: 650px;
    max-width: 85%;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .droneContainer {
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .pilotContainer {
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .droneLeftContainer {
    float: none;
    position: relative;
    margin: auto;
  }
  .rightDroneContainer {
    float: none;
    position: relative;
    margin: auto;
    top: -60px;
  }
  #spec-table {
    top: 0px;
    float: none;
    position: relative;
    margin: auto;
    width: 350px;
    font-size: 12px;
  }
  .pilotInner {
    float: none;
    position: relative;
    margin: auto;
    top: 50px;
    width: 100%;
  }

  #eventShowButton2 {
    margin-top: 0px;
    margin-left: 0px;
  }
  #eventShowButton2Next {
    float: right;
    margin-top: 0px;
    margin-right: 0px;
  }
  #addDroneButton {
    float: none;
    position: relative;
    margin: auto;
    left: 15vw;
  }
  #drone-dropdown {
    float: none;
    position: relative;
    margin: auto;
    left: 12vw;
    top: -30px;
  }
}
@media (max-width: 1200px) and (min-width: 600px) {
  .basket-calendar {
    top: 0px;
    float: none;
    margin: auto;
    position: relative;
    right: 0px;
    width: 370px;
    font-size: 9px;
  }
  .eventContainer {
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .rightEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }

  .eventNameRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }
  #reasonBoxDiv {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }
  .large {
    max-width: 100vw;
    width: 90vw;
  }

  .checkEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }

  .leftEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: -50px;
    left: 0px;
  }
  .endTimePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .endDatePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .startDatePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .startTimePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  #eventShowButton1 {
    top: 790px;
    margin-right: 0px;
    font-size: 12px;
  }
  .allUsers {
    max-height: 500px;
    overflow: auto;
    color: #fff;
    width: 100%;
    margin: auto;
  }
  #allUsers td {
    font-weight: normal;
    max-height: 5px;
    padding: 5px;
    margin: auto;
    font-size: 14px;
    color: #fff;
  }
  #allUsers th {
    padding: 5px;
    font-size: 14px;
    color: #fff;
  }
  .droneContainer {
    width: 55vw;
    min-width: 370px;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .droneLeftContainer {
    float: none;
    position: relative;
    margin: auto;
  }
  .rightDroneContainer {
    float: none;
    position: relative;
    margin: auto;
    top: -60px;
  }
  #spec-table {
    top: -30px;
    float: none;
    position: relative;
    margin: auto;
    width: 350px;
    font-size: 12px;
  }
  .pilotInner {
    float: none;
    position: relative;
    margin: auto;
    top: 50px;
    width: 300px;
  }
  #eventShowButton2 {
    margin-top: 0px;
    top: 700px;
    margin-left: 0px;
  }
  #eventShowButton2Next {
    float: right;
    margin-top: 0px;
    margin-right: 0px;
    top: 700px;
  }
  #addDroneButton {
    float: none;
    position: relative;
    margin: auto;
    left: 11vw;
  }
  #drone-dropdown {
    float: none;
    position: relative;
    margin: auto;
    left: 10vw;
    top: -30px;
  }
  .inputDivTitle2 {
    font-size: 16px;
  }
  .pilotContainer {
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .pilotInner {
    width: 90%;
  }
  .basket-body {
    font-size: 12px;
    margin-top: 70px;
  }
  .inputDivTitle3 {
    font-size: 18px;
  }
  #allBasketClearButton {
    font-size: 10px;
    width: 60px;
    height: 30px;
    top: 20px;
  }
  #basketClearButton {
    font-size: 10px;
    width: 60px;
    height: 25px;
  }
  #eventShowButton3 {
    font-size: 10px;
  }

  #basketPageSubmitButton {
    font-size: 10px;
  }
  .infoText {
    font-size: 16px;
    width: 400px;
    margin: auto;
    margin-top: 20px;
  }
  button#showPasswordButtonBasketPage {
    position: absolute;
    left: 360px;
    bottom: 242px;
  }
  button#showPasswordAgainButtonBasketPage {
    position: absolute;
    left: 360px;
    bottom: 187px;
  }
  .techDropdown {
    font-size: 12px;
    height: 40px;
  }
  #phoneNumInput3 {
    width: 60px;
    padding: 7px;
    height: 40px;
  }

  button#sureButtonUserEdit {
    font-size: 12px;
  }
  button#cancelButtonUserEdit {
    font-size: 12px;
  }
  input#editPhoneNumSelectInput3 {
    width: 60px;
    height: 40px;
  }
  .new-user-input2 {
    height: 40px;
    font-size: 12px;
  }
  input#phoneNumInput3 {
    width: 90px;
  }
}
@media (max-width: 600px) and (min-width: 450px) {
  .infoText {
    font-size: 16px;
    width: 400px;
    margin: auto;
    margin-top: 20px;
  }

  .basket-calendar {
    font-size: 9px;
    top: 0px;
    width: 370px;
    float: none;
    margin: auto;
    position: relative;
    left: 0px;
  }
  .eventContainer {
    width: 55vw;
    min-width: 370px;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .rightEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
    font-size: 12px;
  }
  .eventNameRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }
  #reasonBoxDiv {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }

  .checkEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }
  .privacyCheck {
    font-size: 12px;
  }
  #reasonBox {
    font-size: 12px;
  }
  #termsButton {
    font-size: 12px;
  }
  .leftEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: -50px;
    left: 0px;
    font-size: 12px;
  }
  .endTimePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .endDatePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .startDatePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .event-input-basket {
    width: 200px;
  }
  .startTimePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .inputDivTitle {
    font-size: 20px;
  }
  #eventShowButton1 {
    top: 720px;
    margin-right: -10px;
    font-size: 12px;
  }
  .droneContainer {
    min-width: 370px;
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .droneLeftContainer {
    float: none;
    position: relative;
    margin: auto;
  }
  .rightDroneContainer {
    float: none;
    position: relative;
    margin: auto;
    top: -60px;
  }
  #spec-table {
    top: -30px;
    float: none;
    position: relative;
    margin: auto;
    width: 350px;
    font-size: 12px;
  }
  .pilotInner {
    float: left;
    position: relative;
    margin: auto;
    top: 50px;
    width: 100%;
    left: 0px;
    margin-left: 0px;
  }
  #eventShowButton2 {
    top: 700px;
    margin-left: 0px;
  }
  #eventShowButton2Next {
    float: right;
    top: 700px;
    margin-right: 0px;
  }
  #addDroneButton {
    float: none;
    position: relative;
    margin: auto;
    top: -40px;
    left: 3vw;
  }
  #drone-dropdown {
    float: none;
    position: relative;
    margin: auto;
    left: 0vw;
    top: -40px;
  }
  .inputDivTitle2 {
    font-size: 16px;
  }
  .pilotContainer {
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .basket-body {
    font-size: 12px;
    margin-top: 40px;
    min-width: 200px;
    width: 95%;
    margin-left: 5px;
  }
  .inputDivTitle3 {
    font-size: 18px;
  }
  #allBasketClearButton {
    font-size: 10px;
    width: 60px;
    height: 30px;
    top: 0px;
  }
  #basketClearButton {
    font-size: 10px;
    width: 60px;
    height: 25px;
  }
  #eventShowButton3 {
    font-size: 10px;
    margin-left: -10px;
  }

  #basketPageSubmitButton {
    font-size: 10px;
    margin-right: -10px;
  }
}
@media (max-width: 450px) {
  .infoText {
    font-size: 14px;
    width: 300px;
    margin: auto;
    margin-top: 20px;
  }

  .basket-calendar {
    font-size: 10px;
    top: 0px;
    width: 350px;
    float: none;
    margin: auto;
    position: relative;
    left: 0px;
  }

  .eventContainer {
    min-width: 360px;
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .rightEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: -50px;
    left: 0px;
    font-size: 14px;
  }
  .eventNameRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }
  #reasonBoxDiv {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 10px;
    left: 0px;
  }

  .checkEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    max-height: 50px;
    top: 10px;
    left: 0px;
  }
  .privacyCheck {
    font-size: 14px;
  }
  #reasonBox {
    font-size: 14px;
    position: relative;
  }
  #termsButton {
    font-size: 14px;
  }
  .leftEvent {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: -50px;
    left: 0px;
    font-size: 14px;
  }
  .endTimePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .endDatePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .startDatePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .event-input-basket {
    width: 200px;
  }
  .startTimePickerRow {
    position: relative;
    margin: auto;
    float: none;
    max-width: 200px;
    top: 0px;
    left: 0px;
  }
  .inputDivTitle {
    font-size: 20px;
  }
  #eventShowButton1 {
    top: 710px;
    margin-right: -10px;
    font-size: 12px;
  }
  .droneContainer {
    min-width: 360px;
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    min-height: 550px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .droneLeftContainer {
    float: none;
    position: relative;
    margin: auto;
  }
  .rightDroneContainer {
    float: none;
    position: relative;
    margin: auto;
    top: -60px;
  }
  #spec-table {
    top: 0px;
    float: none;
    position: relative;
    left: -30px;
    width: 350px;
    font-size: 14px;
    max-height: 290px;
    min-width: 300px;
  }
  .pilotInner {
    float: none;
    position: relative;
    left: -30px;
    top: 0px;
    width: 250px;
    min-width: 300px;
    font-size: 14px;
  }

  #eventShowButton2 {
    margin-bottom: -15vh;
    margin-left: 0px;
    font-size: 12px;
  }
  #eventShowButton2Next {
    float: right;
    margin-bottom: -5vh;
    margin-right: 0px;
    font-size: 12px;
  }
  #addDroneButton {
    float: none;
    position: relative;
    margin: auto;
    left: 5vw;
  }
  #drone-dropdown {
    float: none;
    position: relative;
    margin: auto;
    left: 0vw;
    top: -30px;
  }
  .inputDivTitle2 {
    font-size: 16px;
  }
  .pilotContainer {
    min-width: 360px;
    width: 55vw;
    top: 0px;
    height: 70vh;
    max-height: 570px;
    min-height: 550px;
    float: none;
    margin: auto;
    left: 0px;
    position: relative;
    overflow: auto;
  }
  .basket-body {
    font-size: 12px;
    margin-top: 40px;
    min-width: 100%;
    margin-left: 0px;
  }
  .basketBody {
    width: 115%;
    margin-left: -25px;
    margin-top: 50px;
    height: 250px;
  }
  .inputDivTitle3 {
    font-size: 18px;
  }
  #allBasketClearButton {
    font-size: 10px;
    width: 60px;
    height: 30px;
    top: 0px;
    left: -50px;
  }
  #basketClearButton {
    font-size: 10px;
    width: 60px;
    height: 25px;
  }
  #eventShowButton3 {
    margin-left: 0px;
    font-size: 12px;
  }

  #basketPageSubmitButton {
    margin-right: 3vw;
    font-size: 12px;
  }
}
</style>
